const API_URL = `${process.env.LIVE_API}`;

export async function fetchAPI(
  options: {
    endpoint: string,
    method: string,
    token?: string,
    body?: object
  }
) {
  const {method, token, body, endpoint } = options;
  
  const headers = token
    ? new Headers({
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        token,
      })
    : new Headers({
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      });

  const res = await fetch(`${API_URL}${endpoint}`, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  const json = await res.json();

  if (!res.ok) {
    const error = json.errors
      ? json.errors[0].message
      : json.statusMessage || 'API Error';

    return { error };
  }

  return json;
}