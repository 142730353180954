import { fetchAPI } from "../live-api";
import { getTokenFromCookie } from '../cookies';

export async function getUserDetails() {
    const token = getTokenFromCookie();

    const response = await fetchAPI({
        endpoint: `active-user`,
        method: 'GET',
        token
    });

    if(response.error) {
        throw new Error(response.error);
    }
    
    return response;
}
