import { fetchAPI } from "../live-api";
import { getTokenFromCookie } from '../cookies';

export async function getOrganisationTeams(organisationId: number) {
    const token = getTokenFromCookie();

    const teams = await fetchAPI({
        endpoint: `active-user/organisations/${organisationId}/teams`,
        method: 'GET',
        token
    });

    if(teams.error) {
        throw new Error(teams.error);
    }

    return teams;
}