import { fetchAPI } from "../live-api";

export async function login(email: string, password: string) {
    const body = {
        email,
        password
    };

    return fetchAPI({
        endpoint: 'account/auth/login',
        method: 'POST',
        body
    });
}
