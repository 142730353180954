import router from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../styles/components/all-site-search-modal.module.scss';

interface Props {
  setFocus?: (focusFn: () => void) => void;
  value?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
}

export default function AllSiteSearch({
  setFocus,
  value,
  onChange,
  onSubmit,
  placeholder = 'What are you looking for?',
}: Props): JSX.Element {
  const [searchTerm, setSearchTerm] = useState(value || '');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onSubmitHandler =
    onSubmit ||
    ((searchTerm) => {
      router.push(`/search/?s=${searchTerm}`);
    });

  useEffect(() => {
    if (typeof value === 'undefined') {
      return;
    }
    setSearchTerm(value);
  }, [value]);

  const focus = useCallback(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    setFocus && setFocus(focus);
  }, [setFocus, focus]);

  return (
    <div className={styles.container}>
      <input
        ref={inputRef}
        type="text"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmitHandler(searchTerm);
          }
        }}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onChange && onChange(e.target.value);
        }}
        className={styles.input}
        placeholder={placeholder}
      />
      <button
        className={styles.search_button}
        onClick={() => onSubmitHandler(searchTerm)}
      >
        Search
      </button>
    </div>
  );
}
