import { PropsWithChildren, useEffect } from 'react';
import styles from '../styles/components/header-modal.module.scss';
import closeIcon from '../public/images/icons/close.svg';
import Image from 'next/image';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  menuClassName?: string;
}

export default function HeaderModal({
  open,
  setOpen,
  menuClassName,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  useEffect(() => {
    const outsideClickHandler = (e: MouseEvent) => {
      if (!open) {
        return;
      }
      if (
        e.target &&
        e.target instanceof HTMLElement &&
        !e.target.closest(`.${styles.root}`)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', outsideClickHandler);

    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [setOpen, open]);

  return (
    <div
      className={`${styles.root} ${open ? styles.open : ''} ${menuClassName}`}
    >
      {children}

      <button
        type="button"
        className={styles.close_button}
        onClick={() => setOpen(false)}
      >
        <Image
          src={closeIcon.src}
          alt="Mobile Menu Close"
          height={closeIcon.height}
          width={closeIcon.width}
        />
      </button>
    </div>
  );
}
