import { 
  getTokenFromCookie, 
  getContextFromCookie, 
  saveTokenToCookie, 
  saveContextToCookie,
  removeTokenFromCookie,
  removeContextFromCookie
} from './cookies';

import { login as loginUser } from './live-apis/login';
import { getOrganisationTeams } from './live-apis/get-organisation-teams';
import { getUserDetails } from './live-apis/get-user-details';
import { getProductAccess } from './live-apis/get-product-access';
import { Account } from '../types/account';

export function isLoggedIn() {
  const token = getTokenFromCookie();
  return token && token !== '0';
}

export function initAccount() : Account | undefined {
  const context = getContextFromCookie();
  return isLoggedIn() 
    ? { 
      context, 
      products: [] 
    } 
    : undefined;
}

export async function loadAccount() {
  const account = initAccount();

  if(account) {
    const { id, name, email, phone } = await getUserDetails();
    account.user = { id, name, email, phone };
    account.products = await getProductAccess();
  }

  return account;
}

export async function login(email: string, password: string) {
  let response = await loginUser(email, password);

  if(response.error) {
    return response;
  }

  const { token, tokenExpiry, user } = response;
  const { myOrganisationId } = user; 
  
  saveTokenToCookie(token, tokenExpiry);
  await setUserContext(myOrganisationId);

  return response;
}

export function logout() {
  removeTokenFromCookie();
  removeContextFromCookie();
}

async function setUserContext(myOrganisationId: number) {
  const teams = await getOrganisationTeams(myOrganisationId);
  let myTeamId = teams[0] && teams[0].id;
  saveContextToCookie(myOrganisationId, myTeamId);
}
