import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>> &
  React.RefAttributes<HTMLAnchorElement>;

export default function CustomLink({ href, children, ...props }: Props) {
  const router = useRouter();

  return (
    <a
      href={href}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        href && router.push(href);
      }}
    >
      {children}
    </a>
  );
}
