import styles from '../styles/components/nine-dot-menu.module.scss';
import Image from 'next/image';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import Link from 'next/link';
import type { Product as ProductType } from '../types/live-apis/product';
import { logout } from '../lib/account';
import { useRouter } from 'next/router';

interface Props {
  products: Array<ProductType>;
  className?: string
}

export default function NineDotMenu({ products, className }: Props): JSX.Element {
  const router = useRouter();
  
  function handleLogout() {
    logout();
    router.reload();
  }

  return (
    <div className={`${styles.nine_dot_menu__menu} ${className || ''}`}>
      <div className={styles.nine_dot_menu__products}>
        {products.length > 0 && products.map((product, idx) => {
          return (
            <div key={idx} className={styles.nine_dot_menu__product}>
              <Link href={product.url} rel="noopener noreferrer" target="_blank">

                <div className={styles.nine_dot_menu__product_icon}>
                  <SVG src={product.iconLogoLocation} aria-label={product.name} />
                </div>
                <span className={styles.nine_dot_menu__product_name}>
                  {product.name}
                </span>

              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles.nine_dot_menu__logout}>
        <div className="btn">
          <a onClick={() => handleLogout()}>Logout</a>
        </div>
      </div>
    </div>
  );
}
