import styles from '../styles/components/menu.module.scss';
import NineDotMenu from './nine-dot-menu';
import hamburgerIcon from '../public/images/hamburger.svg';
import closeIcon from '../public/images/close.svg';
import nineDotIcon from '../public/images/icons/nine-dot.svg';
import searchIcon from '../public/images/icons/search.png';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import type { Menu as MenuType } from '../types/menu';
import type { Account as AccountType } from '../types/account';
import AllSiteSearch from './all-site-search';
import HeaderModal from './header-modal';
import router from 'next/router';
import CustomLink from './common/custom-link';

import Link from 'next/link';

interface Props {
  menu: MenuType;
  account?: AccountType;
  isNotTop: boolean;
}

export default function Menu({ menu, account, isNotTop }: Props): JSX.Element {
  const [menuActive, setMenuActive] = useState(false);
  const [menuType, setMenuType] = useState('hamburger');
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [searchFocusFn, setSearchFocusFn] = useState<(() => void) | null>(null);

  useEffect(() => {
    if (searchModalOpen) {
      searchFocusFn && searchFocusFn();
    }
  }, [searchModalOpen, searchFocusFn]);

  function toggleMenu() {
    setMenuActive(!menuActive);
    setMenuType('hamburger');
    if (!menuActive) {
      document.querySelectorAll('html')[0].classList.add('no-overflow');
    } else {
      document.querySelectorAll('html')[0].classList.remove('no-overflow');
    }
  }

  function toggleNineDotMenu() {
    setMenuActive(!menuActive);
    setMenuType('ninedot');
    if (!menuActive) {
      document.querySelectorAll('html')[0].classList.add('no-overflow');
    } else {
      document.querySelectorAll('html')[0].classList.remove('no-overflow');
    }
  }

  function removeMenuOpen() {
    setMenuActive(false);
    document.querySelectorAll('html')[0].classList.remove('no-overflow');
  }

  return (
    <div className={`${styles.menu} ${isNotTop ? styles.menu_scrolled : ''}`}>
      <div className={styles.wrapper}>
        {menu?.menuItems?.nodes?.map((menuItem, idx) => {
          return (
            <div key={idx} className={styles.item_wrapper}>
              <CustomLink href={menuItem.path} className={styles.item}>
                {menuItem.label}
              </CustomLink>
              {menuItem?.childItems.nodes.length > 0 && (
                <div className={styles.mega_menu}>
                  <h2>{menuItem.label}</h2>
                  <div className={styles.mega_menu_inner}>
                    {menuItem?.childItems?.nodes?.map((subMenuItem, subIdx) => {
                      return (
                        <div key={subIdx} className={styles.sub_item_wrapper}>
                          <CustomLink
                            key={idx}
                            href={subMenuItem.path}
                            className={styles.sub_item}
                          >
                            {subMenuItem.label}
                          </CustomLink>
                          {subMenuItem?.childItems.nodes.length > 0 && (
                            <div className={styles.sub_item_items}>
                              {subMenuItem?.childItems?.nodes?.map(
                                (subSubMenuItem, subIdx) => {
                                  return (
                                    <div
                                      key={subIdx}
                                      className={styles.sub_sub_item_wrapper}
                                    >
                                      <CustomLink
                                        key={idx}
                                        href={subSubMenuItem.path}
                                        className={styles.sub_sub_item}
                                      >
                                        {subSubMenuItem.label}
                                      </CustomLink>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {account && (
          <>
            <Link href="/account" className={styles.item}>
              Account
            </Link>
            <span className={`${styles.item} ${styles.nine_dot}`}>
              <Image
                src={nineDotIcon.src}
                alt="product menu"
                height={nineDotIcon.height}
                width={nineDotIcon.width}
                className={styles.nine_dot_menu__icon}
              />
              <NineDotMenu
                products={account.products}
                className={styles.nine_dot_menu}
              />
            </span>
          </>
        )}
        <div
          className={`${styles.item} ${styles.search}`}
          onClick={(e) => {
            e.stopPropagation();
            setSearchModalOpen(!searchModalOpen);
          }}
        >
          <Image
            src={searchIcon.src}
            alt="Search Icon"
            onClick={toggleMenu}
            height={searchIcon.height}
            width={searchIcon.width}
          />
        </div>

        {/* login button start */}
        <div className={styles.menu_login_btn_wrapper}>
          {account ? //     Account // <Link href="/account" className={styles.item}>
          // </Link>
          null : (
            <div className={`${styles.login_button} btn`}>
              <Link href="/login">Login</Link>
            </div>
          )}
        </div>
        {/* login button end */}

        <div
          className={`${styles.item_wrapper} ${styles.search_modal_wrapper} ${styles.click}`}
        >
          <a className={styles.item}>&nbsp;</a>
          <HeaderModal
            open={searchModalOpen}
            setOpen={setSearchModalOpen}
            menuClassName={`${styles.mega_menu} ${styles.search_mega_menu} ${
              searchModalOpen ? styles.open : ''
            }`}
          >
            <AllSiteSearch
              setFocus={(fn) => setSearchFocusFn(fn)}
              onSubmit={(searchTerm) => {
                router.push(`/search/?s=${searchTerm}`);
                setSearchModalOpen(false);
              }}
            />
          </HeaderModal>
        </div>
      </div>

      <div className={styles.menu_mobile}>
        {account && (
          <span
            className={
              menuActive
                ? styles.menu_mobile_icon +
                  ' ' +
                  styles.menu_mobile_9dot_icon +
                  ' ' +
                  styles.hide_icon
                : styles.menu_mobile_icon + ' ' + styles.menu_mobile_9dot_icon
            }
          >
            <Image
              src={nineDotIcon.src}
              alt="product menu"
              onClick={toggleNineDotMenu}
              height={nineDotIcon.height}
              width={nineDotIcon.width}
            />
          </span>
        )}
        <div
          className={
            menuActive
              ? styles.menu_mobile_icon + ' ' + styles.hide_icon
              : styles.menu_mobile_icon
          }
        >
          <Image
            src={hamburgerIcon.src}
            alt="Mobile Menu"
            onClick={toggleMenu}
            height={hamburgerIcon.height}
            width={hamburgerIcon.width}
          />
        </div>
        <div
          className={
            !menuActive
              ? styles.menu_mobile_icon + ' ' + styles.hide_icon
              : styles.menu_mobile_icon
          }
        >
          <Image
            src={closeIcon.src}
            alt="Mobile Menu Close"
            onClick={toggleMenu}
            height={closeIcon.height}
            width={closeIcon.width}
          />
        </div>
        <div
          className={
            menuActive
              ? styles.menu_mobile_slideout + ' ' + styles.active
              : styles.menu_mobile_slideout
          }
        >
          {menuType === 'hamburger' && (
            <div className={styles.menu_mobile_slideout_wrapper}>
              {menu?.menuItems?.nodes?.map((menuItem, idx) => {
                return (
                  <CustomLink
                    key={idx}
                    href={menuItem.path}
                    className={styles.item_mobile}
                    onClick={removeMenuOpen}
                  >
                    {menuItem.label}
                  </CustomLink>
                );
              })}
              {account ? (
                <Link href="/account" className={styles.item_mobile}>
                  Account
                </Link>
              ) : (
                <div className={`${styles.login__button} btn`}>
                  <Link href="/login">Login</Link>
                </div>
              )}
            </div>
          )}
          {account && menuType === 'ninedot' && (
            <NineDotMenu
              products={account.products}
              className={styles.menu_mobile_9dot_menu}
            />
          )}
        </div>
      </div>
    </div>
  );
}
