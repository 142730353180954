import Footer from './footer';
import Meta from './meta';
import Header from './header';
import type { Footer as FooterType } from '../types/footer';
import type { Header as HeaderType } from '../types/header';
import { Account as AccountType } from '../types/account';

interface Props {
  children: React.ReactNode;
  header: HeaderType;
  footer?: FooterType;
  account?: AccountType;
}

export default function Layout({
  children,
  header,
  footer,
  account
}: Props): JSX.Element {
  return (
    <>
      <Meta />
      <Header header={header} account={account} />
      <div className="">
        <main className="main">{children}</main>
      </div>
      {footer && <Footer footer={footer} />}
    </>
  );
}
