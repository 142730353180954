import { fetchAPI } from "../live-api";
import { getTokenFromCookie, getContextFromCookie } from '../cookies';

export async function getProductAccess() {
    const token = getTokenFromCookie();
    const { organisationId, teamId } = getContextFromCookie();

    const response = await fetchAPI({
        endpoint: teamId 
            ? `active-user/organisations/${organisationId}/teams/${teamId}/product-access`
            : `active-user/organisations/${organisationId}/product-access`,
        method: 'GET',
        token
    });

    if(response.error) {
        throw new Error(response.error);
    }
    
    return response;
}
