import styles from '../styles/components/header.module.scss';
import { useEffect } from 'react';
import Link from 'next/link';
import headerLogo from '../public/images/roy-morgan-logo.png';
import type { Header as HeaderType } from '../types/header';
import Menu from './menu';
import TopMenu from './top-menu';
import { useState } from 'react';
import type { Account as AccountType } from '../types/account'

interface Props {
  header: HeaderType;
  account?: AccountType;
}

export default function Header({ header, account }: Props): JSX.Element {
  const [isNotTop, setIsNotTop] = useState(Boolean);

  const headerText = header?.generalSettings?.title
  ? header.generalSettings.title
  : '';
  const menu = header?.menu;
  const topmenu = header?.topmenu;

  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      setTimeout(() => {
        window.addEventListener('scroll', () => {
          let isNotTop = true;
          isNotTop = window.scrollY > 0;
          setIsNotTop(isNotTop);
        });
      }, 100);
    }
  }, [setIsNotTop]);

  return (
    <header className={`${styles.wrapper} ${isNotTop ? styles.scrolled_header : ''}`}>
      {/* uncomment to display top menu */}
      {/* {topmenu && <TopMenu menu={topmenu} account={account} isNotTop={isNotTop} />} */}
      <div className={styles.inner}>
        <div className="container">
          <div className={styles.logo_wrapper}>
            <Link href="/" className={styles.logo_link}>

              {header?.themeSettings?.headerLogo?.mediaItemUrl ? (
                <img
                  src={header?.themeSettings?.headerLogo?.mediaItemUrl}
                  alt={headerText}
                  className={styles.logo_image}
                />
              ) : null}

            </Link>
          </div>
          {menu && <Menu menu={menu} account={account} isNotTop={isNotTop} />}
        </div>
      </div>
    </header>
  );
}
