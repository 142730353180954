import Cookies from 'js-cookie';

const SITE_URL = `${process.env.SITE_URL}`;

const TOKEN_COOKIE = 'token';
const ORGANISATION_COOKIE = 'currentOrganisationId';
const TEAM_COOKIE = 'currentTeamId';

export function getTokenFromCookie() {
  return Cookies.get(TOKEN_COOKIE);
}

export function saveTokenToCookie(token: string, expiry?: string | number) {
  const options = getCookieOptions(expiry);
  Cookies.set(TOKEN_COOKIE, token, options);
}

export function removeTokenFromCookie() {
  const options = getCookieOptions();
  Cookies.remove(TOKEN_COOKIE, options);
}

export function getContextFromCookie() {
  let organisationId = Cookies.get(ORGANISATION_COOKIE);
  let teamId = Cookies.get(TEAM_COOKIE);
  return { organisationId, teamId: teamId === '0' ? undefined : teamId };
}

export function saveContextToCookie(organisationId: number, teamId?: number, expiry?: string | number) {
  const currentOrganisationId = organisationId.toString();
  const currentTeamId = teamId ? teamId.toString() : '0';
  const options = getCookieOptions(expiry);

  Cookies.set(ORGANISATION_COOKIE, currentOrganisationId, options);
  Cookies.set(TEAM_COOKIE, currentTeamId, options);
}

export function removeContextFromCookie() {
  const options = getCookieOptions();
  Cookies.remove(ORGANISATION_COOKIE, options);
  Cookies.remove(TEAM_COOKIE, options);
}

function getCookieOptions(expiry?: string | number) {
  const domain = getCookieDomain();
  const expires = expiry
    ? getCookieExpirationTime(expiry)
    : undefined;

  return domain
    ? { domain, expires }
    : { expires };
}

function getCookieDomain() {
  let domain = '.' + SITE_URL.split('/')[2];
  return domain.includes('localhost') ? undefined : domain;
}

function getCookieExpirationTime(expiry?: string | number) {
  const currentTime = new Date().getTime();
  const eightHours = 8 * 60 * 60 * 1000;

  return expiry
    ? new Date(expiry)
    : new Date(currentTime + eightHours);
}
